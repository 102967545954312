import './App.css';
import { useState } from 'react';

//Data: Focus, Prieskum preferencií politických strán bol uskutočnený v období od 9.8.2023 do 16.8.2023 na vzorke 1009 respondentov

const questions = ["Zamestnávanie pracovníkov z krajín mimo Európskej únie by malo byť jednoduchšie.", 
"Mali by sme mať len jednu, štátnu, zdravotnú poisťovňu.", 
"So zámerom zvýšiť kvalitu zákrokov v nemocniciach by štát mal pristúpiť k stratifikácii a teda aj obmedzeniu zákrokov v niektorých menších nemocniciach.",
"Ženy by mali mať možnosť umelo prerušiť tehotenstvo potratovou tabletkou ako alternatívu k chirurgickému zákroku.",
"Slovensko by malo Ukrajinu podporovať aj poskytnutím vojenskej techniky.",
"Sexuálna výchova by mala byť povinným vyučovacím predmetom vo vybraných ročníkoch na základných a stredných školách.",
"Tresty za užívanie a držbu marihuany by mali byť nižšie.",
"Som za možnosť registrovaného partnerstva aj pre páry rovnakého pohlavia.",
"Slovensko by malo politicky nasledovať cestu Maďarska.",
];

const descs = [
  'Zamestnávanie cudzincov na Slovensku v súčasnosti komplikuje niekoľko zákonných opatrení. Niektoré pracovné pozície (vrátane tých v zdravotníctve) máme pritom problém obsadiť. Práve kvalifikovaní pracovníci zo zahraničia by s jeho riešením mohli pomôcť. Odporcovia návrhu sa obávajú úbytku pracovných miest pre domácich zamestnancov.',
  'Politikom presadzujúcim zrušenie súkromných zdravotných poisťovní prekáža napríklad fakt, že dokážu generovať zisk. Odporcovia návrhu upozorňujú na neexistenciu konkurencie a teda súťaže poisťovní, ktoré v boji o zákazníka prinášajú také služby, aby ho získali.',
  'Špecifické a zložité zákroky sa v menších okresoch a na menej navštevovaných nemocniciach robia len zriedka, čo prináša ich nižšiu kvalitu a horšiu úspešnosť. K zrušeniu nemocníc alebo ich častí by malo dôjsť takým spôsobom, aby nebola narušená ich dostupnosť. Slovensko má podľa OECD v rámci EÚ nadpriemerný počet nemocničných lôžok.',
  'Potratové tabletky sú podľa odborníkov menej rizikové, teda bezpečnejšie. Využívajú sa v Rakúsku, Česku či ďalších 20 krajinách Európskej únie a ich používanie odporúča aj WHO. Na Slovensku ich lekári pre zákonné bariéry poskytnúť nesmú.',
  'Slovensko momentálne patrí k západným krajinám, ktoré rôznymi spôsobmi podporujú legitímne sa brániacu Ukrajinu. Medzi tieto spôsoby patrí aj predaj či odovzdanie vojenskej techniky, za ktorú vo väčšine prípadov dostávame jej novšiu verziu alebo peniaze od našich partnerov. Kritici tvrdia, že takáto podpora zaťahuje Slovensko do vojny.',
  'Predmet má žiakom poskytnúť informácie o sexuálnom správaní, ako aj poznatky o reprodukcii, bezpečnom sexe alebo antikoncepcii a chrániť tak zdravie mladých ľudí. Povinná sexuálna výchova je vo viac ako polovici štátov Európskej únie.',
  'Za prechovávanie do troch dávok marihuany pre vlastnú potrebu hrozia na Slovensku 3 roky odňatia slobody. Pokiaľ je dávok viac a súd vyhodnotí, že nie sú len pre vlastnú potrebu, tresty siahajú k 10 rokom, v najhorších prípadoch to môže byť až 25 rokov väzenia. Podľa rôznych ukazovateľov môžu byť pritom riziká užívania marihuany porovnateľné s rizikami pri užívaní alkoholu.',
  'Inštitút registrovaného partnerstva funguje vo viacerých krajinách EÚ. Má upravovať praktické otázky spolužitia v domácnosti, spoločného majetku, dedenia, vzájomného zastupovania, vyživovacej povinnosti a podobne. Na zavedenie manželstva pre páry rovnakého pohlavia by bola potrebná zmena ústavy.',
  'Viacerí slovenskí politici chvália neúplnú demokraciu v Maďarsku, tvrdí EÚ. To má aktuálne problém s čerpaním eurofondov aj znižujúcou sa slobodou médií. Odvolávajú sa pritom na údajnú šikovnosť a suverénnu politiku premiéra Orbána.',
];


const links = [
  '',
  '',
  '',
  'https://www.who.int/publications/i/item/9789240039483',
  '',
  'https://ec.europa.eu/social/BlobServlet?docId=23654&langId=en',
  'https://drugabuse.com/blog/marijuana-vs-alcohol/',
  '',
  'https://www.europarl.europa.eu/news/sk/press-room/20220909IPR40137/poslanci-ep-madarsko-uz-nemozno-povazovat-za-uplnu-demokraciu',
];


const emojis = [
  "👷", 
  "💉", 
  "🏥",
  "💊",
  "🇺🇦",
  "📕",
  "🌿",
  "👩‍❤️‍👩",
  "🇭🇺",
];

//responses = 1=Ano,0=Nie,null=Nezaujali

const allParties = [
  {
   "number": 1,
   "fullname": "Pirátska strana - Slovensko",
   "shortname": "Piráti",
   "people": 60,
   "preferences": 0,
   "values": [null, null, null, null, null, null, null, null, null]
  },
  {
   "number": 2,
   "fullname": "PRINCÍP",
   "shortname": "PRINCÍP",
   "people": 70,
   "preferences": 0,
   "values": [null, null, null, null, null, null, null, null, null]
  },
  {
   "number": 3,
   "fullname": "Progresívne Slovensko",
   "shortname": "PS",
   "people": 150,
   "preferences": 15,
   "values": [1,0,1,1,1,1,1,1,0]
  },
  {
   "number": 4,
   "fullname": "SPOLOČNE OBČANIA SLOVENSKA",
   "shortname": "SOS",
   "people": 148,
   "preferences": 0,
   "values": [0,1,0,null,0,null,0,0,null]
  },
  {
   "number": 5,
   "fullname": "OĽANO A PRIATELIA: OBYČAJNÍ ĽUDIA (OĽANO), NEZÁVISLÍ KANDIDÁTI (NEKA), NOVA, SLOBODNÍ A ZODPOVEDNÍ, PAČIVALE ROMA, MAGYAR SZÍVEK a Kresťanská únia a ZA ĽUDÍ",
   "shortname": "OĽANO A PRIATELIA, KÚ a ZA ĽUDÍ",
   "people": 150,
   "preferences": 6.4,
   "coalition": true,
   "values": [null,1,1,0,1,0,1,0,0],
  },
  {
   "number": 6,
   "fullname": "Komunistická strana Slovenska",
   "shortname": "KSS",
   "people": 81,
   "preferences": 0,
   "values": [null, null, null, null, null, null, null, null, null]
  },
  {
   "number": 7,
   "fullname": "Maďarské fórum, Občianski demokrati Slovenska, Za regióny, Rómska koalícia, Demokratická strana",
   "shortname": "MF, ODS, ZR, RK, DS",
   "people": 125,
   "preferences": 0.7,
   "values": [null, null, null, null, null, null, null, null, null]
  },
  {
   "number": 8,
   "fullname": "Vlastenecký blok",
   "shortname": "VB",
   "people": 41,
   "preferences": 0,
   "values": [null, null, null, null, null, null, null, null, null]
  },
  {
   "number": 9,
   "fullname": "Modrí, Most - Híd",
   "shortname": "MM",
   "people": 149,
   "preferences": 1.7,
   "values": [null, null, null, null, null, null, null, null, null]
  },
  {
   "number": 10,
   "fullname": "SPRAVODLIVOSŤ",
   "shortname": "SPRAVODLIVOSŤ",
   "people": 27,
   "preferences": 0,
   "values": [null, null, null, null, null, null, null, null, null]
  },
  {
   "number": 11,
   "fullname": "Slovenské Hnutie Obrody",
   "shortname": "SHO",
   "people": 45,
   "preferences": 0,
   "values": [0,1,1,1,0,1,1,0,0]
  },
  {
   "number": 12,
   "fullname": "Sloboda a Solidarita",
   "shortname": "SaS",
   "people": 150,
   "preferences": 6.1,
   "values": [1,0,1,1,1,1,1,1,0]
  },
  {
   "number": 13,
   "fullname": "SME RODINA",
   "shortname": "SME RODINA",
   "people": 150,
   "preferences": 5.1,
   "values": [0,0,0,0,1,0,1,0,0]
  },
  {
   "number": 14,
   "fullname": "MySlovensko",
   "shortname": "MySlovensko",
   "people": 49,
   "preferences": 0,
   "values": [1,1,1,1,1,1,1,1,0]
  },
  {
   "number": 15,
   "fullname": "Slovenská národná strana",
   "shortname": "SNS",
   "people": 150,
   "preferences": 5.3,
   "values": [0,0,0,0,0,0,1,0,1]
  },
  {
   "number": 16,
   "fullname": "SMER - sociálna demokracia",
   "shortname": "SMER - SD",
   "people": 150,
   "preferences": 20,
   "values": [0,1,0,0,0,0,0,0,1],
  },
  {
   "number": 17,
   "fullname": "HLAS - sociálna demokracia",
   "shortname": "HLAS - SD",
   "people": 150,
   "preferences": 14.2,
   "values": [1,0,1,1,1,1,1,0,0]
  },
  {
   "number": 18,
   "fullname": "SZÖVETSÉG - Magyarok. Nemzetiségek. Regiók. | ALIANCIA - Maďari. Národnosti. Regióny",
   "shortname": "SZÖVETSÉG - ALIANCIA",
   "people": 149,
   "preferences": 3.4,
   "values": [null, null, null, null, null, null, null, null, null]
  },
  {
   "number": 19,
   "fullname": "SRDCE vlastenci a dôchodcovia - SLOVENSKÁ NÁRODNÁ JEDNOTA",
   "shortname": "SRDCE - SNJ",
   "people": 100,
   "preferences": 0,
   "values": [null, null, null, null, null, null, null, null, null]
  },
  {
   "number": 20,
   "fullname": "SDKÚ - DS - Slovenská demokratická a kresťanská únia - Demokratická strana",
   "shortname": "SDKÚ - DS",
   "people": 11,
   "preferences": 0,
   "values": [null, null, null, null, null, null, null, null, null]
  },
  {
   "number": 21,
   "fullname": "Kotlebovci - Ľudová strana Naše Slovensko",
   "shortname": "ĽSNS",
   "people": 150,
   "preferences": 2.1,
   "values": [null, null, null, null, null, null, null, null, null]
  },
  {
   "number": 22,
   "fullname": "Demokrati",
   "shortname": "Demokrati",
   "people": 150,
   "preferences": 3.1,
   "values": [1,0,1,null,1,null,1,null,0]
  },
  {
   "number": 23,
   "fullname": "Kresťanskodemokratické hnutie",
   "shortname": "KDH",
   "people": 150,
   "preferences": 6.1,
   "values": [1,0,0,0,1,0,1,0,0],
  },
  {
   "number": 24,
   "fullname": "KARMA",
   "shortname": "KARMA",
   "people": 17,
   "preferences": 0,
   "values": [null, null, null, null, null, null, null, null, null]
  },
  {
   "number": 25,
   "fullname": "REPUBLIKA",
   "shortname": "REPUBLIKA",
   "people": 150,
   "preferences": 8.8,
   "values": [0,1,0,0,0,0,0,0,1]
  },
 ];


function vibe(t) {
  console.log({t});
  try {
    navigator.vibrate(t);
  } catch (error) {

  }
}


function compare(a, b) {
  if(!a || !b) return 0;
  let same = 0;
  a.forEach((element, index) => {
    if(element === b[index]) {
      same++;
    }
  });
  return same;
}

function evaluate(parties, responses) {
  const maxMatch = responses.length;
  const results = [];
  const used = [];
  for (let index = maxMatch; index > 0; index--) {
     parties.forEach(party => {
      if(used.includes(party)) return;
      if(compare(party.values, responses) === index) {
        used.push(party);
        results.push({
          match: (index/maxMatch)*100,
          party: party,
          matched: index,
        });
      }
     });
    
  }
  return results;
}

function App() {

  const [isQuiz, setIsQuiz] = useState(false);
  const [question, setQuestion] = useState(0);
  const [isIntro, setIsIntro] = useState(true);
  const [isResult, setIsResult] = useState(false);
  const [hideIrrelevant, setHideIrrelevant] = useState(true);
  const [hideHideIrrelevant, setHideHideIrrelevant] = useState(false);
  const [responses, setResponses] = useState([]);

  const parties = allParties.filter((item) => { if(!hideIrrelevant) { return true; }else{ 
    if(item?.coalition) return item.preferences >= 6;
    return item.preferences >= 4; } });

  //console.log(parties);
/*

 {
      value: 1,
      title: "✅ Áno"
    },
    {
      value: 0,
      title: "❌ Nie"
    }, 
    {
      value: null,
      title: "🤔 Neviem sa vyjadriť"
    },
    
    */
  const options = [
    {
      value: 1,
      title: "Áno"
    },
    {
      value: 0,
      title: "Nie"
    }, 
    {
      value: null,
      title: "Neviem sa vyjadriť"
    },
  ];

  const result = evaluate(parties, responses);

  if(!result.length && responses.length === questions.length) {
    if(hideIrrelevant) {
      setHideHideIrrelevant(true);
      setHideIrrelevant(false);
      return;
    }
    alert("Nenašla sa žiadna strana so zhodou, skúste to prosím znova.");
    setQuestion(0);
    setIsQuiz(true);
    setIsIntro(false);
    setIsResult(false);
    setHideIrrelevant(true);
    setHideHideIrrelevant(false);
    setResponses([]);
    return;
  }

  //console.log(result);


  return (
    <div className="App">
      {isIntro && <div className="App-content">
        <p style={{fontSize: "150%", marginBottom: 0, fontWeight: 700}}>Volebná kalkulačka</p>
        <p>
        Čaká ťa 9 otázok, vyberaj vždy odpoveď, s ktorou sa najviac stotožňuješ.
        <br /><small><br />Naša kalkulačka je <b>rýchla a zjednodušená</b>.<br />Ak hľadáš podrobnú kalkulačku, skús <a href='https://zmudrig.sk/volebna-kalkulacka/' target='_blank' rel='nofollow noopener noreferrer'>ZmudriG</a>, <a href='https://www.volebnakalkulacka.sk/' target='_blank' rel='nofollow noopener noreferrer'>VolebnáKalkulačka.sk</a> alebo <a href='https://euandi2019.eui.eu/survey/sk/slovensko2023.html' target='_blank' rel='nofollow noopener noreferrer'>Volebný kompas</a>.</small>
        </p>

      

        <a
        
          className="App-link"
          href="#start"
          onClick={(e) => { e.preventDefault(); setTimeout(() => { setIsIntro(false); setIsQuiz(true); }, 500); }}
        >
        Pokračovať →
        </a> 

      </div>}



      {isQuiz && <div className="App-content">


      <div className='emoji'>{emojis[question]}</div>
        <p style={{marginBottom: 0, fontWeight: 700}}>
          {questions[question]}
        </p>
        <p className='question-info'>{descs[question]}{links[question]!=='' ? <sup><a href={links[question]} target='_blank' rel='nofollow noopener noreferrer'>[zdroj]</a></sup> : <></>}</p>
        {options.map((answer, i) => {
          return (<button data-value={ answer.value } style={{
            
            borderRadius: 160,
            padding: 6,
            fontSize: 20,
            marginBottom: 5,
          }} key={answer.value} onClick={(e) => {
            vibe([50]);
            setTimeout(() => {

              let resp = [...responses];
              resp[question] = answer.value;

              setResponses(resp);
 
              if ((questions.length - 1) > question) {
                setQuestion(question + 1);

              } else {
                const resultObject = {
                    responses: resp,
                    results: evaluate(parties, resp),
                    fullResults: evaluate(allParties, resp),
                  };
                
                  (async () => {
                    const rawResponse = await fetch('/submit.php', {
                      method: 'POST',
                      headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify(resultObject)
                    });
                    await rawResponse.json();
                  })();

                setIsQuiz(false);
                setIsResult(true);

      
                  
                  vibe([50, 50, 50, 50, 50, 50, 50, 50, 500, 500]);
    
   
              }

              
              //setScore(newScore);
            }, 500);

          }}>{answer.title}</button>);
        })}



      </div>}

      {isResult && <div className="App-content">
       <p style={{fontSize: "150%", marginBottom: 0, fontWeight: 700}}>Tvoj výsledok</p>
       <div><small><small><a href='#reset' onClick={(e) => { e.preventDefault(); setTimeout(() => { 
        setIsIntro(true); 
        setIsQuiz(false);
       setQuestion(0);
    setIsResult(false);
    setResponses([]);
    setHideIrrelevant(true);
    setHideHideIrrelevant(false);
     }, 500); }}>Skúsiť znova</a></small></small></div>
        <div style={{ padding: 10, width: '90vw' }}>
        <div className='App-progress'>
            <div style={{textAlign: "center", fontSize: 22, width: result[0].match+"%" }}>{Math.ceil(result[0].match) + "%"}</div>
          </div>
          <p style={{marginBottom: 0}}><span className='kruzkuj'>{result[0].party.number}.</span> <span style={{fontWeight: 700}}>{result[0].party.fullname}</span>{result[0].party.fullname !== result[0].party.shortname ? " ("+result[0].party.shortname+")" : ""}
          <br /><small>zhoda v {result[0].matched} z {responses.length} odpovedí</small></p>
          {result.length > 1 && result.map((res, index) => {
            if(!index || res.matched !== result[0].matched) return null;
            return <p key={res.party.number} style={{marginTop: 0, marginBottom: 0}}><small><small style={{paddingBottom: 10}}> - alebo - </small></small><br /><span className='kruzkuj'>{res.party.number}.</span> <span style={{fontWeight: 700}}>{res.party.fullname}</span>{res.party.fullname !== res.party.shortname ? " ("+res.party.shortname+")" : ""}
          <br /><small>zhoda v {res.matched} z {responses.length} odpovedí</small></p>;
          })}

          

            {!hideHideIrrelevant && <div className='normal-font' style={{marginTop: '20px'}}>
              <label>
                <input
                  type="checkbox"
                  checked={hideIrrelevant}
                  onChange={() => { setHideIrrelevant(!hideIrrelevant); }}
                />
                Vynechať strany, ktoré nemajú podľa prieskumov šancu dostať sa do parlamentu
              </label>

              {!!hideIrrelevant && false && <p>Boli vynechané strany s preferenciami nižšími než 4 % (na zvolenie potrebuje strana minimálne 5 %).</p>}
            </div>}

            <p><small><a href="https://kampanpredemokraciu.sk/jednorazova/">Darovať na prevádzku volebnej kalkulačky a Kampane pre demokraciu.</a></small></p>
        </div>
      
      </div>}
    </div>
  );
}

export default App;
